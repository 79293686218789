import i18next from "i18next";
import { useContext, createContext, useState, useEffect } from "react";
import cookieService from "../services/cookie";

const rtlLanguages = ['ar']

const context = createContext({})

export const useMainContext = () => {
    return useContext(context)
}

const MainContextProvider = ({ children }) => {
    const [lang, setLang] = useState(cookieService.get('lang') || "en")
    const [token, _setToken] = useState(cookieService.get('token'))
    const [isRtl, setRtl] = useState(false)
    const [globalLoading, setGlobalLoading] = useState(false)
    console.log({ token })

    useEffect(() => {
        i18next.changeLanguage(lang)
        cookieService.set('lang', lang, { path: '/' })
        setRtl(rtlLanguages.includes(lang))
    }, [lang])

    const setToken = (token, internalNavigation = true) => {
        cookieService.set('token', token, { path: '/' })
        if (internalNavigation) _setToken(token)
    }

    const changeLang = (l) => {
        setLang(l)
    }

    return (
        <context.Provider value={{
            token,
            setToken,
            lang,
            changeLang,
            isRtl,
            globalLoading,
            setGlobalLoading
        }}>
            {children}
        </context.Provider>
    )
}

export default MainContextProvider;
