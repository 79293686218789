import { createContext, useContext, useEffect, useState } from "react";
import { MutationCache, QueryCache, QueryClient } from "react-query";
import { useToast } from '@chakra-ui/react'


const context = createContext({})

export const useQueryContext = () => {
    return useContext(context)
}

const QueryContextProvider = ({ children }) => {

    const [queryClient, setQueryClient] = useState(new QueryClient())
    const toast = useToast()

    useEffect(() => {
        const client = new QueryClient({
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                    select: res => res.data,
                    retry: false
                }
            },
            queryCache: new QueryCache({
                onError: (res) => {
                    toast({
                        title: res?.response?.data?.status,
                        description: res?.response?.data?.message,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                }
            }),
            mutationCache: new MutationCache({
                onError: (res) => {
                    toast({
                        title: res?.response?.data?.status,
                        description: res?.response?.data?.message,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                }
            })
        })
        setQueryClient(client)
    }, [])

    return (
        <context.Provider
            value={{
                queryClient,
            }}
        >
            {children}
        </context.Provider>
    )
}

export default QueryContextProvider
