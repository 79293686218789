import axios from "axios";
import { useMainContext } from "../contexts/main";

const useSetAxiosHeaders = () => {

    const { token, lang } = useMainContext()

    axios.defaults.headers['Authorization'] = `Bearer ${token}`
    axios.defaults.headers['Accept-Language'] = lang
}



export {
    useSetAxiosHeaders
}
